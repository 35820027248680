html, body {
	height: 100%;
	padding: 0;
	margin: 0;
	overflow: initial !important;
}

body {
	font-family: Arial, sans-serif;
	font-size: 16px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

::-webkit-input-placeholder {
   color: #363534;
}

:-moz-placeholder {
   color: #363534;
}

::-moz-placeholder {
  color: #363534;
}

:-ms-input-placeholder {
   color: #363534;
}

.container {
	width: 1001px;
	margin: 0 auto;
}

.course-body-copy {
	background: url('/img/laser-institute/bg-page.jpg') no-repeat;
	width: 1001px;
	height: 1506px;
}

.form-cnt {
	background: #006bb3;
	padding: 60px 64px;
}

form#interest_form h3 {
	background: url('/img/laser-institute/form-heading.png') no-repeat;
	text-indent: -9999px;
	margin: 0 0 13px;
}

form ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	width: 568px;
}

form ul li {
	margin-bottom: 14px;
}

form ul li:last-child {
	margin: 0;
}

form ul li > * {
	display: inline-block;
	vertical-align: top;
}

form ul li input {
	color: #363534;
	border: 0 none;
	padding: 10px 9px;
}

form ul li input#fname {
	width: 260px;
	margin-right: 7px;
}

form ul li input#lname {
	width: 260px;
}

form ul li input#email {
	width: 260px;
	margin-right: 18px;
}

form ul li input#email-verify {
	width: 260px;
	margin-right: 18px;
}

form ul li input#city {
	width: 249px;
}

form ul li select {
	border: 0 none;
	height: 28px;
	width: 100%;
}

form ul li select {
	color: #363534;
	padding: 10px 9px;
	height: 35px;
}

form ul li select#state-select {
	width: 150px;
}

form ul li select option {
	color: #363534;
}

form ul li input[type='submit'] {
	background: #0b3070;
	color: #ffffff;
	border: 1px solid #ffffff;
	cursor: pointer;
}