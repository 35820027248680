@font-face {
  font-family: 'Folio';
  src: url("../fonts/FolioLig.otf");
  /* IE9 Compat Modes */
  src: url("../fonts/FolioLig.otf?#iefix") format("embedded-opentype"), url("../fonts/FolioLig.otf") format("woff"), url("../fonts/FolioLig.otf") format("truetype");
}

@font-face {
  font-family: 'Folio Medium';
  src: url("../fonts/FolioMed.otf");
  /* IE9 Compat Modes */
  src: url("../fonts/FolioMed.otf?#iefix") format("embedded-opentype"), url("../fonts/FolioMed.otf") format("woff"), url("../fonts/FolioMed.otf") format("truetype");
}

@font-face {
  font-family: 'Folio Bold';
  src: url("../fonts/FolioBol.otf");
  /* IE9 Compat Modes */
  src: url("../fonts/FolioBol.otf?#iefix") format("embedded-opentype"), url("../fonts/FolioBol.otf") format("woff"), url("../fonts/FolioBol.otf") format("truetype");
}

@font-face {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro"), url("../fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  white-space: normal;
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.popover > .arrow, .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow:after {
  border-width: 10px;
  content: "";
}

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}

.popover.top > .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.right > .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}

.popover.bottom > .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left > .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, Arial, Verdana;
  color: rgba(0, 0, 0, 0.75);
  overflow: auto !important;
}

.clearfix {
  clear: both;
}

.text-danger {
  color: red !important;
}

.text-highlight {
  color: #9fd443 !important;
}

.text-light-blue {
  color: rgba(20, 92, 121, 0.75) !important;
}

.text-theme-blue {
  color: #1F9BB4 !important;
}

.light-blue-bg {
  background: rgba(0, 0, 0, 0.06);
}

.relative {
  position: relative;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline, .pager, .xmedica-paginator .pagination {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.list-inline > li, .pager > li, .xmedica-paginator .pagination > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.inline-block {
  display: inline-block;
}

.inline-block .form-error {
  display: block;
}

.default-hidden {
  display: none !important;
}

.pager span,
.pager a {
  color: #a6a6a6;
  font-size: 13px;
}

.pager li {
  padding: 0;
}

.pager a {
  text-decoration: none;
}

.pager a:hover, .pager a:focus, .pager a:active, .pager a.active {
  text-decoration: none;
  color: #4d4d4d;
}

.xmedica-paginator .pagination {
  display: inline-block;
}

.xmedica-paginator .pagination span,
.xmedica-paginator .pagination a {
  color: #a6a6a6;
  font-size: 13px;
}

.xmedica-paginator .pagination li {
  padding: 0 2px;
}

.xmedica-paginator .pagination li a {
  text-decoration: none;
}

.xmedica-paginator .pagination li a:hover, .xmedica-paginator .pagination li a:focus, .xmedica-paginator .pagination li a:active {
  text-decoration: none;
  color: #4d4d4d;
}

.xmedica-paginator .pagination li.active span {
  color: #4d4d4d;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn:hover, .btn:focus, .btn:active {
  text-decoration: none;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

.btn-primary {
  color: white;
  background-color: #9fd443;
  border-radius: 22px;
}

.social-media {
  padding-right: 6px;
}

.social-media a {
  text-align: center;
  font-family: 'Folio Medium', Helvetica, Arial, Verdana;
  color: white;
  text-decoration: none;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-radius: 7px;
  font-size: 23px;
  line-height: 100%;
}

.social-media .twitter-btn {
  line-height: 17px;
  font-size: 19px;
  vertical-align: top;
}

.md-social-media {
  display: inline-block;
  margin-top: 8px;
  float: left;
  margin-left: 14px;
}

.md-social-media a {
  display: inline-block;
  background-size: 100% 100%;
}

.md-social-media a.fb-btn, .md-social-media a.twitter-btn {
  width: 33px;
  height: 32px;
}

.md-social-media a.fb-btn {
  background-image: url("/img/fb.png");
}

.md-social-media a.twitter-btn {
  margin-left: 11px;
  background-image: url("/img/twit.png");
}

.thumbnail .caption {
  padding: 9px 22px;
}

.no-top-spacing {
  margin-top: 0;
}

.sm-top-spacing {
  margin-top: 10px;
}

.top-spacing {
  margin-top: 20px;
}

.md-top-spacing {
  margin-top: 40px;
}

.lg-bottom-padding {
  padding-bottom: 80px;
}

.no-padding {
  padding: 0 !important;
}

.auto-fit {
  max-width: 100%;
}

.block {
  width: 100%;
}

/**************** Form *************************/
.form-group {
  margin-bottom: 20px;
}

.form-input {
  color: #404040;
  display: block;
  width: 100%;
  height: 30px;
  padding: 8px 17px;
  font-size: 14px;
  line-height: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
}

select.form-input {
  padding: 6px 12px;
}

:-ms-input-placeholder,
::-ms-placeholder {
  /* Internet Explorer 10+ */
  color: #404040;
  opacity: 1;
}

:-moz-placeholder,
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #404040;
  opacity: 1;
}

::placeholder {
  color: #404040;
  opacity: 1;
}

::-webkit-input-placeholder {
  color: #404040;
  opacity: 1;
}

/**************** Bootstrap ********************/
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-family: Arial, Helvetica, Arial, Verdana;
}

.alert.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert .dismissible {
  display: inline;
  float: right;
}

.alert a {
  text-decoration: none;
}

.alert .dismissible-danger a {
  color: #a94442;
}

.alert .dismissible-success a {
  color: #3c763d;
}

/**************** General *********************/
.page-wrapper {
  width: 980px !important;
  margin: 20px auto auto auto;
  overflow: hidden;
  box-shadow: 10px 0 25px -8px rgba(0, 0, 0, 0.3), -10px 0 25px -8px rgba(0, 0, 0, 0.3);
}

.page-wrapper.event-page-wrapper {
  padding-top: 15px;
  border-top-left-radius: 0;
  margin: auto;
}

/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {
  /* iPhone 11 Pro Max, Xs Max ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .page-wrapper {
      min-height: 205vh;
    }
  }
  /* iPhone 11, XR ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
    .page-wrapper {
      min-height: 205vh;
    }
  }
  /* iPhone 11 Pro, X, Xs ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .page-wrapper {
      min-height: 220vh;
    }
  }
  /* iPhone 6+, 6s+, 7+ and 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .page-wrapper {
      min-height: 210vh;
    }
  }
  /* iPhone 6, 6s, 7 and 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .page-wrapper {
      min-height: 230vh;
    }
  }
  /* iPhone 5, 5S, 5C and SE ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .page-wrapper {
      min-height: 262vh;
    }
  }
  /* iPhone 4, 4S ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .page-wrapper {
      min-height: 280vh;
    }
  }
}

.alert-wrapper {
  width: 980px;
  margin: auto;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  clear: both;
  margin-left: -15px;
  margin-right: -15px;
}

.row:before, .row:after {
  display: table;
  content: " ";
}

.row:after {
  clear: both;
}

/*************** Custom grid **********************/
.col-1 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 8.3333333333%;
}

.col-2 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 16.6666666667%;
}

.col-3 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 25%;
}

.col-4 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 33.3333333333%;
}

.col-5 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 41.6666666667%;
}

.col-6 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
}

.col-7 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 58.3333333333%;
}

.col-8 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 66.6666666667%;
}

.col-9 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 75%;
}

.col-10 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 83.3333333333%;
}

.col-11 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 91.6666666667%;
}

.col-12 {
  float: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.col-offset-1 {
  margin-left: 7.560296846%;
}

.col-offset-2 {
  margin-left: 8.4693877551%;
}

.col-offset-3 {
  margin-left: 9.5804988662%;
}

.col-offset-4 {
  margin-left: 10.9693877551%;
}

.col-offset-5 {
  margin-left: 12.7551020408%;
}

.col-offset-6 {
  margin-left: 15.1360544218%;
}

.col-offset-7 {
  margin-left: 18.4693877551%;
}

.col-offset-8 {
  margin-left: 23.4693877551%;
}

.col-offset-9 {
  margin-left: 31.8027210884%;
}

.col-offset-10 {
  margin-left: 48.4693877551%;
}

.col-offset-11 {
  margin-left: 98.4693877551%;
}

.col-offset-12 {
  margin-left: Infinity-1.5306122449%;
}

/*************** Header **********************/
.header {
  background-color: #146079;
  background: url(../img/main-header.png) no-repeat;
  padding: 15px 0;
  height: 112px;
  border-bottom: 3px #9fd443 solid;
  position: relative;
  z-index: 10;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.header * {
  color: white;
}

.header a:hover,
.header a:focus,
.header a:active {
  color: white;
}

.header .header-title-container,
.header .header-navbar-container {
  display: inline-block;
  float: right;
  padding-left: 20px;
  padding-right: 10px;
}

.header .header-title-container {
  width: 280px;
  display: inline-block;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
}

.header .header-title-container .header-logo {
  height: 65px;
  margin-top: 3px;
}

.header .header-title-container .site-title {
  font-family: 'Folio Bold', Helvetica, Arial, Verdana;
  margin: 0;
  padding: 0;
  line-height: 39px;
  font-size: 39px;
  font-weight: normal;
  margin-top: 18px;
  margin-left: 8px;
}

.header .header-title-container .site-title sup {
  display: inline-block;
  font-size: 8px;
  vertical-align: top;
  margin-top: 12px;
  margin-left: -4px;
  font-family: Arial, Helvetica, Arial, Verdana;
}

.header .header-navbar-container .social-media {
  padding-right: 6px;
}

.header .header-navbar-container .social-media a {
  text-align: center;
  font-family: 'Folio Medium', Helvetica, Arial, Verdana;
  color: white;
  text-decoration: none;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-radius: 7px;
  font-size: 23px;
  line-height: 100%;
}

.header .header-navbar-container .social-media .twitter-btn {
  line-height: 17px;
  font-size: 19px;
  vertical-align: top;
  margin-left: 3px;
}

.header .header-navbar-container ul.nav-bar .dropmenu {
  position: relative;
}

.header .header-navbar-container ul.nav-bar .dropmenu ul {
  width: 200px;
  position: absolute;
  background-color: transparent;
  display: none;
  left: -5px;
  z-index: 1000;
  top: 12px;
}

.header .header-navbar-container ul.nav-bar .dropmenu ul li.first {
  margin-top: 17px;
}

.header .header-navbar-container ul.nav-bar .dropmenu ul li {
  background-color: rgba(0, 40, 60, 0.75);
  padding: 4px 0px 6px 10px;
}

.header .header-navbar-container ul.nav-bar .dropmenu ul a {
  display: block;
  color: white;
  font-size: 14px;
}

.header .header-navbar-container ul.nav-bar .dropmenu:hover ul {
  display: inline-block;
}

.header .header-navbar-container .nav-bar {
  margin-top: 0;
  padding-top: 12px;
  margin-bottom: 0;
  text-align: right;
  padding-right: 20px;
}

.header .header-navbar-container .nav-bar li {
  padding-left: 7px;
  text-align: left;
}

.header .header-navbar-container .nav-bar li a {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
}

.header .header-navbar-container .nav-bar li a span {
  position: absolute;
  width: 15px;
  height: 13px;
  top: -18px;
  left: 50%;
  margin-left: -9.5px;
  background: url("/img/arrow.png") no-repeat center top;
  visibility: hidden;
}

.header .header-navbar-container .nav-bar li a:hover, .header .header-navbar-container .nav-bar li a:active, .header .header-navbar-container .nav-bar li a:focus {
  text-decoration: none;
  color: #9FD444;
}

.header .header-navbar-container .nav-bar li a:hover span, .header .header-navbar-container .nav-bar li a:active span, .header .header-navbar-container .nav-bar li a:focus span {
  visibility: visible;
}

.header .header-navbar-container .nav-bar li.active a {
  text-decoration: none;
  color: #9FD444;
}

.header .header-navbar-container .nav-bar li.active a span {
  visibility: visible;
}

.header .header-navbar-container .nav-bar li.last {
  padding-right: 0;
}

/*************** Banner **********************/
.banner .banner-image-container {
  background: url("/img/banner1.png") no-repeat;
  height: 305px;
  background-size: 100% 100%;
}

.banner .banner-image-container .banner-menu {
  margin: 0;
  padding: 5px 56px 10px 0px;
  background-color: rgba(0, 0, 0, 0.4);
  background-repeat: repeat-x;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(0, 0, 0, 0.4)), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0));
  -pie-background: linear-gradient(#ddd, #000);
  behavior: url(/pie/PIE.htc);
}

.banner .banner-image-container .banner-menu li {
  padding-left: 3px;
}

.banner .banner-image-container .banner-menu li a {
  color: white;
  text-decoration: none;
  font-size: 13px;
}

.banner .banner-image-container .banner-menu li a:hover, .banner .banner-image-container .banner-menu li a:focus, .banner .banner-image-container .banner-menu li a:active, .banner .banner-image-container .banner-menu li a.active {
  text-decoration: none;
  color: #9fd443;
}

.banner .banner-overlay {
  width: 914px;
  border-top-right-radius: 30px;
  overflow: hidden;
  margin-top: -84px;
}

.banner .banner-overlay .banner-overlay-title,
.banner .banner-overlay .banner-overlay-description,
.banner .banner-overlay .banner-overlay-form,
.banner .banner-overlay .banner-footer {
  padding-left: 66px;
  padding-right: 25px;
}

.banner .banner-overlay .banner-overlay-title {
  background: rgba(0, 40, 60, 0.75);
  padding-top: 14px;
}

.banner .banner-overlay .banner-overlay-description,
.banner .banner-overlay .banner-overlay-form,
.banner .banner-overlay .banner-footer {
  background: #006176;
  padding-bottom: 20px;
}

.banner .banner-overlay p,
.banner .banner-overlay h1,
.banner .banner-overlay h2,
.banner .banner-overlay h3,
.banner .banner-overlay h4,
.banner .banner-overlay h5 {
  color: white;
}

.banner .banner-overlay .form-input,
.banner .banner-overlay .form-input option {
  color: rgba(0, 0, 0, 0.75);
}

.banner .banner-overlay h3 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
  font-weight: normal;
}

.banner .banner-overlay h3 strong {
  font-family: 'Arial Black', Helvetica, Arial, Verdana;
}

.banner .banner-overlay .btn-lg {
  color: white;
  background-color: #9fd443;
  border-radius: 22px;
  padding: 6px 16px;
  width: 127px;
  margin-top: 8px;
}

.banner .banner-overlay p {
  font-size: 14px;
  margin-top: 17px;
  line-height: 19px;
  margin-bottom: 5px;
}

.banner .banner-overlay .top-arrow {
  display: block;
  margin-top: 32px;
  margin-left: -9px;
}

.banner.registration-banner .banner-image-container {
  background: url("/img/registration-banner.png") no-repeat;
  background-size: 100% 100%;
}

/*************** Sections ************/
.section-container {
  padding-left: 66px;
  width: 919px;
}

.section-container .section-heading {
  color: #145c79;
  font-size: 36px;
  font-weight: normal;
  margin-left: -4px;
  font-family: 'Folio', Helvetica, Arial, Verdana;
  margin-top: 37px;
  margin-bottom: 10px;
}

/*************** News & Noteworthy **********************/
.news-noteworthy {
  color: #145c79;
}

.news-noteworthy .news-container .row.news-row {
  margin-left: -8px;
  margin-right: -5px;
}

.news-noteworthy .news-container .row.news-row .news-box {
  padding-left: 8px;
  padding-right: 2px;
  height: 400px;
  margin-top: 12px;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail-image-container {
  position: relative;
  border-bottom: 5px #57b5c7 solid;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail-image-container .new,
.news-noteworthy .news-container .row.news-row .news-box .thumbnail-image-container .live {
  display: none;
  position: absolute;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail-image-container .new,
.news-noteworthy .news-container .row.news-row .news-box .thumbnail-image-container .live {
  right: 0;
  bottom: 0;
  height: 81px;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail-image-container a {
  display: block;
  line-height: 0;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail-image-container img.thumbnail-image {
  width: 100%;
  border-top-left-radius: 15%;
  min-height: 175px;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail .caption {
  position: relative;
  min-height: 128px;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 13px 15px 9px 21px;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail .caption .title,
.news-noteworthy .news-container .row.news-row .news-box .thumbnail .caption p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail .caption .title {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 19px;
  font-weight: normal;
  min-height: 39px;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail .caption p {
  color: rgba(0, 0, 0, 0.75);
  margin-top: 14px;
  font-size: 14px;
  margin-bottom: 0;
}

.news-noteworthy .news-container .row.news-row .news-box .thumbnail .caption .thumbnail-link {
  position: absolute;
  bottom: 7px;
  right: 11px;
}

.news-noteworthy .news-container .row.news-row .news-box.live .thumbnail-image-container .live {
  display: inline-block;
}

.news-noteworthy .news-container .row.news-row .news-box.new .thumbnail-image-container .new {
  display: inline-block;
}

/*************** Live events **********************/
.live-events {
  color: #145c79;
}

.live-events .events-container .row.events-row {
  background: white;
  margin: -8px 5px 0 -8px;
  padding-top: 7px;
}

.live-events .events-container .row.events-row .event-box {
  padding-left: 8px;
  padding-right: 0;
  margin-bottom: 13px;
}

.live-events .events-container .row.events-row .event-box .thumbnail-image-container {
  position: relative;
  border-bottom: 5px #57b5c7 solid;
}

.live-events .events-container .row.events-row .event-box .thumbnail-image-container a {
  display: block;
  line-height: 0;
}

.live-events .events-container .row.events-row .event-box .thumbnail-image-container img.thumbnail-image {
  width: 100%;
  border-top-left-radius: 15%;
  height: 267px;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption {
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 9px 15px 9px 23px;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption p {
  color: rgba(0, 0, 0, 0.75);
  margin-top: 16px;
  font-size: 14px;
  margin-bottom: 0;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption p.event-date {
  margin-top: 9px;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption p.event-place {
  margin-top: 0;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption .thumbnail-link {
  text-decoration: none;
  display: inline-block;
  position: absolute;
  right: 13px;
  bottom: 14px;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption .thumbnail-link span {
  display: inline-block;
  margin-top: 5px;
  margin-right: 9px;
  font-size: 13px;
}

.live-events .events-container .row.events-row .event-box .thumbnail .caption .thumbnail-link:hover, .live-events .events-container .row.events-row .event-box .thumbnail .caption .thumbnail-link:focus, .live-events .events-container .row.events-row .event-box .thumbnail .caption .thumbnail-link:active {
  text-decoration: none;
}

/*************** Features **********************/
.features .features-container .section-heading {
  margin-top: 34px;
}

.features .features-container .feature-box {
  margin-bottom: 12px;
}

.features .features-container .feature-box .feature-image-container {
  position: relative;
  width: 267px !important;
  padding-right: 0;
  border-right: 20px solid rgba(0, 0, 0, 0.15);
  height: 154px;
}

.features .features-container .feature-box .feature-image-container a {
  line-height: 0;
  display: block;
  height: 100%;
}

.features .features-container .feature-box .feature-image-container img {
  max-width: 100%;
}

.features .features-container .feature-box .feature-image-container img.feature-img {
  width: 100%;
  height: 100%;
}

.features .features-container .feature-box .feature-image-container .feature-link {
  position: absolute;
  top: 23px;
  right: -34px;
}

.features .features-container .feature-box .feature-caption {
  background: rgba(0, 0, 0, 0.06);
  height: 154px;
}

.features .features-container .feature-box .feature-caption .title,
.features .features-container .feature-box .feature-caption .sub-title {
  padding-left: 9px;
  margin-bottom: 0;
}

.features .features-container .feature-box .feature-caption .title {
  margin-top: 23px;
  font-size: 22px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.75);
}

.features .features-container .feature-box .feature-caption .sub-title {
  margin: 0;
  color: rgba(20, 92, 121, 0.75);
  font-weight: normal;
  font-family: Arial;
  font-size: 18px;
  margin-top: -2px;
}

.features .features-container .feature-box .feature-caption p {
  margin-top: 2px;
  padding-left: 9px;
  font-size: 14px;
  line-height: 18px;
  font-family: Arial;
  color: rgba(0, 0, 0, 0.75);
}

/*************** Sponsors **********************/
.partners-sponsors {
  margin-top: 27px;
}

.partners-sponsors .panel {
  font-family: arial;
  margin-bottom: 20px;
  border-color: #ddd;
  height: 240px;
}

.partners-sponsors .panel-body {
  padding: 16px 20px;
  clear: both;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  height: 189px;
}

.partners-sponsors .panel-heading {
  color: white;
  height: 53px;
  padding: 16px 22px;
  border-top-left-radius: 27.5px;
  border-bottom: 1px solid transparent;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.partners-sponsors .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 100%;
  font-weight: normal;
}

.partners-sponsors .panel-title > a {
  color: inherit;
}

.partners-sponsors .partners-panel-container,
.partners-sponsors .partner-with-us-panel-container {
  width: 437px;
}

.partners-sponsors .partners-panel-container {
  padding-right: 5px;
}

.partners-sponsors .partners-panel-container .partners-panel .panel-heading {
  background-color: #146079;
}

.partners-sponsors .partners-panel-container .partners-panel .panel-body .cycle-slideshow {
  max-height: 100%;
}

.partners-sponsors .partners-panel-container .partners-panel .panel-body .cycle-slideshow .cycle-slide {
  right: 0;
  bottom: 0;
  max-height: 100%;
}

.partners-sponsors .partners-panel-container .partners-panel .panel-body .cycle-slideshow .cycle-slide img {
  max-height: 100%;
}

.partners-sponsors .partners-panel-container .partners-panel .panel-body p {
  margin-top: -3px;
  font-size: 15px;
  color: #6C6C6C;
  margin-bottom: 8px;
}

.partners-sponsors .partner-with-us-panel-container {
  padding-right: 12px;
  padding-left: 7px;
}

.partners-sponsors .partner-with-us-panel-container .partner-with-us-panel {
  color: rgba(0, 0, 0, 0.75);
}

.partners-sponsors .partner-with-us-panel-container .partner-with-us-panel .panel-heading {
  background-color: #a0d544;
}

.partners-sponsors .partner-with-us-panel-container .partner-with-us-panel .panel-body {
  padding: 21px 39px;
}

.partners-sponsors .partner-with-us-panel-container .partner-with-us-panel .panel-body p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 18px;
  margin-top: 14px;
}

.partners-sponsors .partner-with-us-panel-container .partner-with-us-panel .panel-body h4 {
  font-size: 21px;
  margin-top: 2px;
  margin-bottom: 4px;
  color: rgba(20, 92, 121, 0.75);
  font-weight: normal;
}

.partners-sponsors .partner-with-us-panel-container .partner-with-us-panel .panel-body h5 {
  margin-top: 0px;
  font-size: 16px;
  font-weight: normal;
}

.partners-sponsors .partner-with-us-panel-container .partner-with-us-panel .panel-body .phone-row {
  padding-left: 11px;
}

/*************** Banners **********************/
.banners .section-container {
  padding-left: 68px;
}

/*************** MedNews **********************/
.mednews .section-container h1 {
  margin-top: 15px;
}

.mednews .panel {
  font-family: arial;
  margin-bottom: 9px;
  border-color: #ddd;
}

.mednews .panel-body {
  clear: both;
  border: 1px solid transparent;
  height: 156px;
}

.mednews .mednews-panel-container {
  padding-right: 5px;
}

.mednews .mednews-panel-container .mednews-panel .panel-body .cycle-slideshow {
  border-top-left-radius: 25px;
  border-top-right-radius: 0;
  max-height: 100%;
}

.mednews .mednews-panel-container .mednews-panel .panel-body .cycle-slideshow .cycle-slide {
  right: 0;
  bottom: 0;
  max-height: 100%;
}

.mednews .mednews-panel-container .mednews-panel .panel-body .cycle-slideshow .cycle-slide img {
  max-height: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 0;
}

.mednews .mednews-panel-container .mednews-panel .panel-body p {
  margin-top: -3px;
  font-size: 15px;
  color: #6C6C6C;
  margin-bottom: 8px;
}

/*************** Footer **********************/
.footer {
  background-color: #146079;
  margin-top: 0px;
  border-top: 3px #9fd443 solid;
}

.footer .footer-navbar {
  padding-top: 7px;
  margin-right: 12px;
}

.footer .footer-navbar li {
  padding: 0 6px;
}

.footer .footer-navbar li,
.footer .footer-navbar a,
.footer .footer-navbar span {
  color: white;
}

.footer .footer-navbar a,
.footer .footer-navbar span {
  text-decoration: none;
  font-family: Arial;
  font-size: 14px;
  display: inline-block;
}

.footer .footer-navbar a:hover, .footer .footer-navbar a:focus, .footer .footer-navbar a:active,
.footer .footer-navbar span:hover,
.footer .footer-navbar span:focus,
.footer .footer-navbar span:active {
  color: white;
  text-decoration: none;
}

.footer .footer-navbar .divider {
  padding: 0;
  margin-right: 0;
  font-size: 14px;
}

.footer .logo-container {
  padding: 12px 8px;
  padding-left: 0;
  margin-left: -18px;
}

.footer .logo-container .site-title {
  color: white;
  font-family: 'Folio Medium', Helvetica, Arial, Verdana;
  font-weight: bold;
  color: white;
  padding: 8px 0;
  font-size: 24px;
  line-height: 21px;
  margin: 0;
  margin-left: 6px;
}

.footer .logo-container .site-title sup {
  top: 3px;
  font-size: 6px;
  display: inline-block;
  vertical-align: top;
}

/*************** Index page  **********************/
.index-banner .banner-overlay {
  margin-top: -86px;
  width: 913px;
}

.index-banner .banner-overlay .banner-overlay-title {
  padding-top: 16px;
}

.index-banner .banner-overlay .banner-overlay-title h3 {
  margin-left: 3px;
}

.index-banner .banner-overlay .banner-overlay-description {
  padding-bottom: 18px;
}

.index-banner .banner-overlay .banner-overlay-description p {
  margin-left: 3px;
  font-size: 14px;
  margin-top: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}

.index-section .section-heading {
  margin-left: 0;
  margin-bottom: 11px;
}

.index-section.news-noteworthy .news-container .news-row .news-box {
  padding-right: 0;
  width: 285px;
  padding-left: 10px;
}

.index-section.news-noteworthy .news-container .news-row .news-box .thumbnail .caption .title {
  margin-top: 7px;
  line-height: 20px;
}

.index-section.news-noteworthy .news-container .news-row .news-box .thumbnail .caption p {
  margin-top: 12px;
  line-height: 19px;
}

.index-section.news-noteworthy .news-container .news-row .news-box .thumbnail .caption div.description {
  color: rgba(0, 0, 0, 0.75);
  padding-right: 0;
  padding-left: 0;
}

.index-section.news-noteworthy .news-container .news-row .news-box .thumbnail .caption div.description .credits {
  float: right;
}

.index-section.features .features-container .section-heading {
  margin-top: 32px;
  margin-bottom: 9px;
}

.index-section.features .features-container .feature-box {
  margin-right: -25px;
}

.index-section.features .features-container .feature-box .feature-image-container {
  padding-left: 17px;
}

.index-section.partners-sponsors {
  margin-top: 25px;
}

.index-section.partners-sponsors .partners-panel-container {
  padding-left: 18px;
}

.index-section.partners-sponsors .partner-with-us-panel-container {
  padding-left: 9px;
}

.index-section.partners-sponsors .partner-with-us-panel-container .panel-body h4 {
  font-size: 20px;
}

.index-section.partners-sponsors .partner-with-us-panel-container .panel-body a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
}

.index-footer.footer {
  margin-top: 54px;
}

/*************** Registration Page **********************/
.banner.registration-banner {
  margin-bottom: 70px;
}

.banner.registration-banner .banner-overlay-form-container {
  background: url("/img/registration-form-bg.png");
  background-size: contain;
}

.banner.registration-banner .popover-title {
  color: black;
  margin-bottom: 0;
}

.banner.registration-banner .banner-overlay-form {
  background: transparent;
  padding-bottom: 0;
  padding-right: 57px;
  padding-left: 0;
}

.banner.registration-banner .banner-overlay-form .form-panel {
  border-color: #ddd;
}

.banner.registration-banner .banner-overlay-form .form-panel.name-panel {
  margin-top: 44px;
}

.banner.registration-banner .banner-overlay-form .form-panel.contact-panel {
  margin-top: 26px;
}

.banner.registration-banner .banner-overlay-form .form-panel.source-panel {
  margin-top: 18px;
}

.banner.registration-banner .banner-overlay-form .form-panel.source-panel .panel-body {
  padding-top: 22px;
}

.banner.registration-banner .banner-overlay-form .form-panel.content-secondary-panel {
  padding-top: 28px;
}

.banner.registration-banner .banner-overlay-form .form-panel .panel-heading,
.banner.registration-banner .banner-overlay-form .form-panel .panel-body {
  padding-left: 66px;
}

.banner.registration-banner .banner-overlay-form .form-panel .panel-body {
  padding-top: 13px;
  clear: both;
  background-color: transparent;
  border: 1px solid transparent;
}

.banner.registration-banner .banner-overlay-form .form-panel .panel-heading {
  padding-top: 7px;
  padding-bottom: 7px;
  background-color: #006176;
  color: white;
  border-top-right-radius: 12px;
  border-bottom: 1px solid transparent;
}

.banner.registration-banner .banner-overlay-form .form-panel .panel-heading .panel-title {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 100%;
  font-weight: normal;
}

.banner.registration-banner .banner-overlay-form .form-panel .panel-heading .panel-title > a {
  color: inherit;
}

.banner.registration-banner .banner-overlay-form .registration-form .form-row {
  overflow: hidden;
  margin-left: -4px;
  margin-bottom: 12px;
}

.banner.registration-banner .banner-overlay-form .registration-form .error-container {
  color: rgba(0, 0, 0, 0.75);
}

.banner.registration-banner .banner-overlay-form .registration-form .form-input {
  display: inline-block;
  float: left;
  width: 261px;
  margin-right: 5px;
}

.banner.registration-banner .banner-overlay-form .registration-form .form-input.last {
  margin-right: 0px;
}

.banner.registration-banner .banner-overlay-form .registration-form .form-input-wrapper {
  display: inline-block;
  float: left;
  width: 261px;
  margin-right: 5px;
}

.banner.registration-banner .banner-overlay-form .registration-form .form-input-wrapper.last {
  margin-right: 0px;
}

.banner.registration-banner .banner-overlay-form .registration-form .form-input-wrapper.state-field-wrapper .form-error {
  display: block;
  clear: both;
  text-align: left;
}

.banner.registration-banner .banner-overlay-form .registration-form .source-field {
  margin-right: 37px;
}

.banner.registration-banner .banner-overlay-form .registration-form select {
  padding: 6px 12px;
}

.banner.registration-banner .banner-overlay-form .registration-form .profession-field,
.banner.registration-banner .banner-overlay-form .registration-form .practice-field {
  width: 382px;
}

.banner.registration-banner .banner-overlay-form .registration-form .state-field {
  width: 85px;
}

.banner.registration-banner .banner-overlay-form .npi-number {
  overflow: hidden;
  border: none;
  background: #006176;
  padding: 20px 17px;
  padding-bottom: 32px;
  margin-top: 37px;
}

.banner.registration-banner .banner-overlay-form .npi-number h4 {
  margin-top: 4px;
  margin-bottom: 9px;
}

.banner.registration-banner .banner-overlay-form .npi-number h4 small {
  font-size: 64%;
}

.banner.registration-banner .banner-overlay-form .npi-number .npi-number-field {
  width: 243px;
}

.banner.registration-banner .banner-overlay-form .npi-number .btn-lg {
  float: left;
  margin-top: -4px;
  width: auto;
}

.banner.registration-banner .banner-overlay-form .npi-number .btn-lg.what-this-btn {
  margin-left: 11px;
  padding: 6px 24px;
}

.banner.registration-banner .banner-overlay-form .npi-number .btn-lg.look-up-npi-btn {
  margin-left: 15px;
  padding: 6px 25px;
}

.banner.registration-banner .banner-overlay-form .form-footer {
  margin-right: -57px;
  margin-top: 128px;
  background-color: #006176;
  padding-left: 66px;
  padding-top: 33px;
  padding-bottom: 33px;
}

.banner.registration-banner .banner-overlay-form .form-footer .btn,
.banner.registration-banner .banner-overlay-form .form-footer p {
  margin-top: 0;
}

.banner.registration-banner .banner-overlay-form .form-footer .btn {
  margin-left: -4px;
}

.banner.registration-banner .banner-overlay-form .form-footer p {
  font-size: 12px;
  margin-left: 8px;
  margin-top: 4px;
  line-height: 15px;
}

.banner.registration-banner .banner-overlay-form .form-footer .confirmation-message {
  font-family: Myriad Pro;
}

/*************** MedChannel Index Page **********************/
.banner.medchannel-banner .banner-overlay {
  margin-top: -86px;
}

.banner.medchannel-banner .banner-overlay .banner-overlay-title {
  padding-top: 16px;
}

.banner.medchannel-banner .banner-overlay .banner-overlay-title h3 {
  margin-bottom: 17px;
}

.banner.medchannel-banner .banner-image-container {
  background: url("/img/medchannel-bg.png");
  background-size: 100% 100%;
}

.banner.medchannel-banner .banner-overlay-form {
  background-color: #1F9BB4;
  padding-top: 21px;
  padding-bottom: 29px;
}

.banner.medchannel-banner .banner-overlay-form .form-input,
.banner.medchannel-banner .banner-overlay-form .or-separator {
  display: inline-block;
}

.banner.medchannel-banner .banner-overlay-form .form-input {
  width: 211px;
  padding: 6px 14px;
  margin-left: 1px;
}

.banner.medchannel-banner .banner-overlay-form .form-input.topic-field {
  padding: 6px 12px;
}

.banner.medchannel-banner .banner-overlay-form .or-separator {
  padding-left: 12px;
  padding-right: 10px;
}

.banner.medchannel-banner .banner-overlay-form .btn-lg {
  margin-top: -3px;
  width: 85px;
  margin-left: 40px;
  padding: 5px 16px;
}

.banner.medchannel-banner .banner-overlay-description {
  background: url("/img/plus.png");
  background-repeat: no-repeat;
  background-size: 255px 240px;
  background-position: 450px 20px;
  padding-bottom: 0;
}

.banner.medchannel-banner .banner-overlay-description .med-channel-img {
  width: 261px;
  margin-top: -67px;
  margin-right: 2px;
}

.banner.medchannel-banner .banner-overlay-description .med-channel-description {
  display: inline-block;
  float: right;
  margin-right: 37px;
}

.banner.medchannel-banner .banner-overlay-description .med-channel-description .medchannel-sub-header {
  width: 342px;
  margin: 86px 2px 0 auto;
}

.banner.medchannel-banner .banner-overlay-description .med-channel-description p {
  margin-top: 13px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.75);
}

.features.medchannel-features .features-container .feature-box {
  margin-bottom: 13px;
  height: 180px;
  overflow: hidden;
}

.features.medchannel-features .features-container .feature-box .feature-image-container,
.features.medchannel-features .features-container .feature-box .feature-caption {
  height: 100%;
}

.features.medchannel-features .features-container .feature-box .feature-caption {
  padding-left: 14px;
  padding-right: 50px;
}

.features.medchannel-features .features-container .feature-box .feature-caption .title {
  margin-top: 23px;
  padding-left: 9px;
  color: rgba(20, 92, 121, 0.75);
  font-weight: normal;
  font-family: Arial;
  font-size: 18px;
}

.features.medchannel-features .features-container .feature-box .feature-caption .title sup {
  font-size: 10px;
}

.features.medchannel-features .features-container .feature-box .feature-caption .course-info {
  margin-bottom: 20px;
}

.features.medchannel-features .features-container .feature-box .feature-caption .course-info .course-category {
  margin-right: 4px;
}

.features.medchannel-features .features-container .feature-box .feature-caption .course-info .course-length {
  padding-left: 7px;
  padding-right: 7px;
}

.features.medchannel-features .features-container .feature-box .feature-caption .course-info .course-length .time {
  padding-left: 5px;
}

.features.medchannel-features .features-container .feature-box .feature-caption .course-info .course-expire-date {
  padding-left: 8px;
}

.features.medchannel-features .features-container .feature-box .feature-caption p {
  margin-top: 0;
  line-height: 20px;
}

.features.medchannel-features .pager {
  margin-top: -2px;
  margin-right: 12px;
  margin-bottom: 0;
}

.footer.medchannel-page-footer {
  margin-top: 15px;
}

/*************** MedChannel Show Page **********************/
.banner.medchannel-program-banner .banner-overlay {
  margin-top: -86px;
}

.banner.medchannel-program-banner .banner-overlay .banner-overlay-title {
  padding-top: 16px;
}

.banner.medchannel-program-banner .banner-overlay .banner-overlay-title h3 {
  margin-bottom: 17px;
}

.banner.medchannel-program-banner .banner-image-container {
  background: url("/img/medchannel-bg.png");
  background-size: 100% 100%;
}

.banner.medchannel-program-banner .banner-overlay-form {
  background-color: #1F9BB4;
  padding-top: 21px;
  padding-bottom: 26px;
}

.banner.medchannel-program-banner .banner-overlay-form .btn-lg {
  margin-top: 7px;
  width: auto;
  padding: 5px 20px;
  float: left;
}

.banner.medchannel-program-banner .banner-overlay-form .btn-lg.share-btn {
  margin-left: 15px;
  padding: 5px 18px;
}

.banner.medchannel-program-banner .banner-overlay-form .social-media {
  display: inline-block;
  margin-top: 8px;
  float: left;
  margin-left: 14px;
}

.banner.medchannel-program-banner .banner-overlay-form .social-media a {
  width: 33px;
  height: 33px;
  font-size: 31px;
  font-family: 'Folio', Helvetica, Arial, Verdana;
  line-height: 34px;
}

.banner.medchannel-program-banner .banner-overlay-form .social-media a.twitter-btn {
  margin-left: 11px;
  line-height: 26px;
  font-size: 30px;
}

.banner.medchannel-program-banner .medchannel-program-banner-container {
  line-height: 0;
}

.banner.medchannel-program-banner .banner-overlay-description {
  padding-bottom: 55px;
}

.banner.medchannel-program-banner .banner-overlay-description .medchannel-program-description h3 {
  margin-top: 27px;
  margin-bottom: 1px;
}

.banner.medchannel-program-banner .banner-overlay-description .medchannel-program-description p {
  line-height: 20px;
}

.banner.medchannel-program-banner .banner-overlay-description .medchannel-program-description p.upload-date {
  margin-top: 0;
  opacity: 0.7;
  margin-bottom: 20px;
}

.banner.medchannel-program-banner .banner-overlay-description .medchannel-program-description .medchannel-description {
  color: white;
  font-size: 14px;
}

.features.medchannel-features.medchannel-related-program {
  margin-bottom: 49px;
}

.features.medchannel-features.medchannel-related-program .related-channel-title {
  font-weight: normal;
  font-size: 22px;
  margin-left: 1px;
  margin-bottom: 15px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box {
  margin-bottom: 13px;
  height: 180px;
  overflow: hidden;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-image-container,
.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption {
  height: 100%;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption {
  padding-left: 14px;
  padding-right: 50px;
  background: transparent;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption .title {
  margin-top: 23px;
  padding-left: 9px;
  color: rgba(20, 92, 121, 0.75);
  font-weight: normal;
  font-family: Arial;
  font-size: 18px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption .title sup {
  font-size: 10px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption .course-info {
  margin-bottom: 20px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption .course-info .course-category {
  margin-right: 4px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption .course-info .course-length {
  padding-left: 7px;
  padding-right: 7px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption .course-info .course-length .time {
  padding-left: 5px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption .course-info .course-expire-date {
  padding-left: 8px;
}

.features.medchannel-features.medchannel-related-program .features-container .feature-box .feature-caption p {
  margin-top: 0;
  line-height: 20px;
}

.footer.medchannel-page-footer {
  margin-top: 15px;
}

/*************** Medflix Index Page **********************/
.banner {
  position: relative;
}

.banner.medflix-banner .banner-overlay {
  margin-top: -86px;
  height: 562px;
}

.banner.medflix-banner .banner-overlay .banner-overlay-title {
  padding-top: 16px;
}

.banner.medflix-banner .banner-overlay .banner-overlay-title h3 {
  margin-bottom: 17px;
}

.banner.medflix-banner .banner-clock-container {
  position: absolute;
  top: 370px;
  right: -110px;
  background-image: url(/img/medflix/clock.png);
  width: 555px;
  height: 556px;
  background-position-y: -64px;
}

.banner.medflix-banner .banner-image-container {
  background: url("/img/medflix-banner.png");
  background-size: 100% 100%;
}

.banner.medflix-banner .banner-overlay-form {
  background-color: #1F9BB4;
  padding-top: 21px;
  padding-bottom: 29px;
}

.banner.medflix-banner .banner-overlay-form .form-input,
.banner.medflix-banner .banner-overlay-form .or-separator {
  display: inline-block;
}

.banner.medflix-banner .banner-overlay-form .form-input {
  width: 211px;
  padding: 6px 14px;
  margin-left: 1px;
}

.banner.medflix-banner .banner-overlay-form .form-input.topic-field {
  padding-left: 10px;
}

.banner.medflix-banner .banner-overlay-form .or-separator {
  padding: 0 12px;
}

.banner.medflix-banner .banner-overlay-form .btn-lg {
  margin-top: -3px;
  width: 85px;
  margin-left: 38px;
  padding: 5px 16px;
}

.banner.medflix-banner .banner-overlay-description {
  background: transparent;
  padding-bottom: 0;
  background: url("/img/medflix-overlay-bg.png");
  background-repeat: no-repeat;
  background-size: 800px 300px;
  background-position: 115px -15px;
  position: relative;
}

.banner.medflix-banner .banner-overlay-description .banner-overlay-container .film-image-container {
  position: absolute;
  left: -128px;
}

.banner.medflix-banner .banner-overlay-description .banner-overlay-container .logo-image-container {
  position: absolute;
  top: 150px;
  left: 76px;
}

.banner.medflix-banner .banner-overlay-description .banner-overlay-container .logo-image-container .logo-sub-text {
  color: rgba(0, 0, 0, 0.75);
  margin-top: 3px;
}

.medflix-list {
  margin-left: -15px;
  margin-right: -15px;
}

.medflix-list .medflix-list-container {
  width: 848px;
  margin: 0 auto;
}

.medflix-list .medflix-list-container .row .medflix-box {
  width: 260px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 10px;
}

.medflix-list .medflix-list-container .row .medflix-box:first-child {
  margin-left: 0 !important;
}

.medflix-list .medflix-list-container .row .medflix-box:last-child {
  margin-right: 0 !important;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail-image-container {
  position: relative;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail-image-container .new,
.medflix-list .medflix-list-container .row .medflix-box .thumbnail-image-container .live {
  display: none;
  position: absolute;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail-image-container .new,
.medflix-list .medflix-list-container .row .medflix-box .thumbnail-image-container .live {
  right: 0;
  bottom: 0;
  height: 81px;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail-image-container a {
  display: block;
  line-height: 0;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail-image-container img.thumbnail-image {
  width: 100%;
  height: 180px;
  border-top-left-radius: 15%;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption {
  position: relative;
  padding: 5px;
  font-size: 12px;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption .course-info {
  line-height: 1.5;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption .course-info .divider:before {
  content: '|';
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption .course-info .divider {
  margin: 0 5px;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption .title,
.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #145c79;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption .title {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 19px;
  font-weight: normal;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption p {
  color: rgba(0, 0, 0, 0.75);
  margin-top: 14px;
  margin-bottom: 0;
}

.medflix-list .medflix-list-container .row .medflix-box .thumbnail .caption .thumbnail-link {
  position: absolute;
  bottom: 7px;
  right: 11px;
}

.features.medfix-features {
  margin-left: -13px;
}

.features.medfix-features .features-container .feature-box {
  margin-bottom: 13px;
  height: 180px;
  overflow: hidden;
}

.features.medfix-features .features-container .feature-box .feature-image-container .feature-link {
  top: 43px;
}

.features.medfix-features .features-container .feature-box .feature-image-container .time {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80px;
  width: 80px;
}

.features.medfix-features .features-container .feature-box .feature-image-container,
.features.medfix-features .features-container .feature-box .feature-caption {
  height: 100%;
}

.features.medfix-features .features-container .feature-box .feature-caption {
  padding-left: 13px;
  padding-right: 50px;
}

.features.medfix-features .features-container .feature-box .feature-caption .title {
  margin-top: 44px;
  padding-left: 9px;
  color: rgba(20, 92, 121, 0.75);
  font-weight: normal;
  font-family: Arial;
  font-size: 18px;
  line-height: 21px;
}

.features.medfix-features .features-container .feature-box .feature-caption .course-info {
  margin-bottom: 20px;
}

.features.medfix-features .features-container .feature-box .feature-caption .course-info .course-category {
  margin-right: 4px;
}

.features.medfix-features .features-container .feature-box .feature-caption .course-info .course-length {
  padding-left: 7px;
  padding-right: 7px;
}

.features.medfix-features .features-container .feature-box .feature-caption .course-info .course-length .time {
  padding-left: 5px;
}

.features.medfix-features .features-container .feature-box .feature-caption .course-info .course-expire-date {
  padding-left: 8px;
}

.features.medfix-features .features-container .feature-box .feature-caption p {
  margin-top: 0;
  line-height: 20px;
}

.features.medfix-features .pager {
  margin-right: 12px;
  margin-bottom: 0;
  margin-top: 14px;
}

.features.medfix-features .pagination {
  margin-right: 12px;
  margin-bottom: 0;
  margin-top: 14px;
}

.footer.medfix-page-footer {
  margin-top: 84px;
}

/*************** Umbrella Course Page **********************/
.cutera .banner.umbrella-banner,
.umbrella .banner.umbrella-banner,
.umbrella-archived .banner.umbrella-banner {
  background-color: #1f9cb4;
}

.cutera .banner.umbrella-banner .banner-overlay-solid,
.umbrella .banner.umbrella-banner .banner-overlay-solid,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid {
  margin-top: -86px;
  width: 914px;
  border-top-right-radius: 30px;
  overflow: hidden;
  margin-top: -84px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title {
  padding-top: 16px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title h3,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title h3,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title h3 {
  margin-bottom: 17px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title a, .cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title a:visited,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title a,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title a:visited,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title a,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title a:visited {
  color: white;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title,
.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-description,
.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-form,
.cutera .banner.umbrella-banner .banner-overlay-solid .banner-footer,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-description,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-form,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-footer,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-description,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-form,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-footer {
  padding-left: 66px;
  padding-right: 25px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-title {
  background: #146079;
  padding-top: 14px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-description,
.cutera .banner.umbrella-banner .banner-overlay-solid .banner-overlay-form,
.cutera .banner.umbrella-banner .banner-overlay-solid .banner-footer,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-description,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-overlay-form,
.umbrella .banner.umbrella-banner .banner-overlay-solid .banner-footer,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-description,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-overlay-form,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .banner-footer {
  background: #006176;
  padding-bottom: 20px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid p,
.cutera .banner.umbrella-banner .banner-overlay-solid h1,
.cutera .banner.umbrella-banner .banner-overlay-solid h2,
.cutera .banner.umbrella-banner .banner-overlay-solid h3,
.cutera .banner.umbrella-banner .banner-overlay-solid h4,
.cutera .banner.umbrella-banner .banner-overlay-solid h5,
.umbrella .banner.umbrella-banner .banner-overlay-solid p,
.umbrella .banner.umbrella-banner .banner-overlay-solid h1,
.umbrella .banner.umbrella-banner .banner-overlay-solid h2,
.umbrella .banner.umbrella-banner .banner-overlay-solid h3,
.umbrella .banner.umbrella-banner .banner-overlay-solid h4,
.umbrella .banner.umbrella-banner .banner-overlay-solid h5,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid p,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid h1,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid h2,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid h3,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid h4,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid h5 {
  color: white;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .form-input,
.cutera .banner.umbrella-banner .banner-overlay-solid .form-input option,
.umbrella .banner.umbrella-banner .banner-overlay-solid .form-input,
.umbrella .banner.umbrella-banner .banner-overlay-solid .form-input option,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .form-input,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .form-input option {
  color: rgba(0, 0, 0, 0.75);
}

.cutera .banner.umbrella-banner .banner-overlay-solid h3,
.umbrella .banner.umbrella-banner .banner-overlay-solid h3,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid h3 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
  font-weight: normal;
}

.cutera .banner.umbrella-banner .banner-overlay-solid h3 strong,
.umbrella .banner.umbrella-banner .banner-overlay-solid h3 strong,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid h3 strong {
  font-family: 'Arial Black', Helvetica, Arial, Verdana;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .btn-lg,
.umbrella .banner.umbrella-banner .banner-overlay-solid .btn-lg,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .btn-lg {
  color: white;
  background-color: #9fd443;
  border-radius: 22px;
  padding: 6px 16px;
  width: 127px;
  margin-top: 8px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid p,
.umbrella .banner.umbrella-banner .banner-overlay-solid p,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid p {
  font-size: 14px;
  margin-top: 17px;
  line-height: 19px;
  margin-bottom: 5px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .top-arrow,
.umbrella .banner.umbrella-banner .banner-overlay-solid .top-arrow,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .top-arrow {
  display: block;
  margin-top: 32px;
  margin-left: -9px;
}

.cutera .banner.umbrella-banner .banner-overlay-solid .ellipsis,
.umbrella .banner.umbrella-banner .banner-overlay-solid .ellipsis,
.umbrella-archived .banner.umbrella-banner .banner-overlay-solid .ellipsis {
  width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cutera .banner.umbrella-banner .banner-image-container,
.umbrella .banner.umbrella-banner .banner-image-container,
.umbrella-archived .banner.umbrella-banner .banner-image-container {
  background: url("/img/medflix-banner.jpg");
  background-size: 100% 100%;
}

.cutera .banner.umbrella-banner .banner-overlay-form,
.umbrella .banner.umbrella-banner .banner-overlay-form,
.umbrella-archived .banner.umbrella-banner .banner-overlay-form {
  background-color: #1F9BB4;
  padding-top: 21px;
  padding-bottom: 29px;
}

.cutera .banner.umbrella-banner .banner-overlay-form .form-input,
.cutera .banner.umbrella-banner .banner-overlay-form .or-separator,
.umbrella .banner.umbrella-banner .banner-overlay-form .form-input,
.umbrella .banner.umbrella-banner .banner-overlay-form .or-separator,
.umbrella-archived .banner.umbrella-banner .banner-overlay-form .form-input,
.umbrella-archived .banner.umbrella-banner .banner-overlay-form .or-separator {
  display: inline-block;
}

.cutera .banner.umbrella-banner .banner-overlay-form .form-input,
.umbrella .banner.umbrella-banner .banner-overlay-form .form-input,
.umbrella-archived .banner.umbrella-banner .banner-overlay-form .form-input {
  width: 211px;
  padding: 6px 14px;
  margin-left: 1px;
}

.cutera .banner.umbrella-banner .banner-overlay-form .form-input.topic-field,
.umbrella .banner.umbrella-banner .banner-overlay-form .form-input.topic-field,
.umbrella-archived .banner.umbrella-banner .banner-overlay-form .form-input.topic-field {
  padding-left: 10px;
}

.cutera .banner.umbrella-banner .banner-overlay-form .or-separator,
.umbrella .banner.umbrella-banner .banner-overlay-form .or-separator,
.umbrella-archived .banner.umbrella-banner .banner-overlay-form .or-separator {
  padding: 0 12px;
}

.cutera .banner.umbrella-banner .banner-overlay-form .btn-lg,
.umbrella .banner.umbrella-banner .banner-overlay-form .btn-lg,
.umbrella-archived .banner.umbrella-banner .banner-overlay-form .btn-lg {
  margin-top: -3px;
  width: 85px;
  margin-left: 38px;
  padding: 5px 16px;
}

.cutera .banner.umbrella-banner .banner-overlay-description,
.umbrella .banner.umbrella-banner .banner-overlay-description,
.umbrella-archived .banner.umbrella-banner .banner-overlay-description {
  background: transparent;
  padding-bottom: 0;
  background: url("/img/medflix-overlay-bg.png");
  background-repeat: no-repeat;
  background-size: 800px 300px;
  background-position: 115px -15px;
}

.cutera .banner.umbrella-banner .banner-overlay-description .medflix-img,
.umbrella .banner.umbrella-banner .banner-overlay-description .medflix-img,
.umbrella-archived .banner.umbrella-banner .banner-overlay-description .medflix-img {
  width: 321px;
  margin-top: -80px;
  margin-right: -27px;
}

.cutera .banner.umbrella-banner .banner-overlay-description .medflix-description,
.umbrella .banner.umbrella-banner .banner-overlay-description .medflix-description,
.umbrella-archived .banner.umbrella-banner .banner-overlay-description .medflix-description {
  display: inline-block;
  float: right;
  margin-right: 9px;
}

.cutera .banner.umbrella-banner .banner-overlay-description .medflix-description .medflix-sub-header,
.umbrella .banner.umbrella-banner .banner-overlay-description .medflix-description .medflix-sub-header,
.umbrella-archived .banner.umbrella-banner .banner-overlay-description .medflix-description .medflix-sub-header {
  margin: 86px -4px 0 auto;
  width: 218px;
}

.cutera .banner.umbrella-banner .banner-overlay-description .medflix-description .medflix-logo-img,
.umbrella .banner.umbrella-banner .banner-overlay-description .medflix-description .medflix-logo-img,
.umbrella-archived .banner.umbrella-banner .banner-overlay-description .medflix-description .medflix-logo-img {
  margin-top: 84px;
  width: 193px;
  margin-right: 2px;
}

.cutera .banner.umbrella-banner .banner-overlay-description .medflix-description p,
.umbrella .banner.umbrella-banner .banner-overlay-description .medflix-description p,
.umbrella-archived .banner.umbrella-banner .banner-overlay-description .medflix-description p {
  margin-top: 10px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.75);
}

.cutera .banner.umbrella-banner .course-content,
.umbrella .banner.umbrella-banner .course-content,
.umbrella-archived .banner.umbrella-banner .course-content {
  font-family: "Folio Bold", Helvetica, Arial, Verdana;
  padding-top: 19px;
  width: 914px;
  height: 100%;
  background-color: #146079;
}

.cutera .banner.umbrella-banner .course-content .course-container,
.umbrella .banner.umbrella-banner .course-content .course-container,
.umbrella-archived .banner.umbrella-banner .course-content .course-container {
  width: 100%;
  margin-bottom: 13px;
  margin-left: 67px;
  background-color: #f0f0f0;
  border-top-left-radius: 25px;
}

.cutera .banner.umbrella-banner .course-content .course-container .placeholder,
.umbrella .banner.umbrella-banner .course-content .course-container .placeholder,
.umbrella-archived .banner.umbrella-banner .course-content .course-container .placeholder {
  height: 180px;
  width: 250px;
  background-size: 100% 100%;
  border-top-left-radius: 25px;
  border-right-style: solid;
  border-right-width: 20px;
  border-right-color: #d9d9d9;
}

.cutera .banner.umbrella-banner .course-content .course-container .feature-link-icon,
.umbrella .banner.umbrella-banner .course-content .course-container .feature-link-icon,
.umbrella-archived .banner.umbrella-banner .course-content .course-container .feature-link-icon {
  position: absolute;
  margin-top: 42px;
  margin-left: -7px;
}

.cutera .banner.umbrella-banner .course-content .course-container .index-container,
.umbrella .banner.umbrella-banner .course-content .course-container .index-container,
.umbrella-archived .banner.umbrella-banner .course-content .course-container .index-container {
  margin-left: 14px;
  font-size: 50px;
  margin-top: 23px;
  color: #146079;
}

.cutera .banner.umbrella-banner .course-content .course-container .course-title-container,
.umbrella .banner.umbrella-banner .course-content .course-container .course-title-container,
.umbrella-archived .banner.umbrella-banner .course-content .course-container .course-title-container {
  margin-top: 44px;
  font-size: 14px;
  color: #50889b;
}

.cutera .banner.umbrella-banner .course-content .course-container .course-detail-container,
.umbrella .banner.umbrella-banner .course-content .course-container .course-detail-container,
.umbrella-archived .banner.umbrella-banner .course-content .course-container .course-detail-container {
  font-family: Arial;
  color: #4b4b4b;
  margin-top: 25px;
  margin-left: 1px;
}

.cutera .footer,
.umbrella .footer,
.umbrella-archived .footer {
  margin-top: 0 !important;
}

.cutera .seperator,
.umbrella .seperator,
.umbrella-archived .seperator {
  height: 26px;
}

/*************** Archived Course Page **********************/
.umbrella-archived .banner.umbrella-banner .archived-description {
  font-size: 14px;
  padding-right: 7px;
  margin-top: 4px;
}

.umbrella-archived .banner.umbrella-banner .banner-image-container {
  background: url("/img/banner1.png");
}

/*************** Live events **********************/
.banner.live-events-banner .banner-overlay {
  margin-top: -86px;
}

.banner.live-events-banner .banner-overlay .banner-overlay-title {
  padding-top: 16px;
}

.banner.live-events-banner .banner-overlay .banner-overlay-title h3 {
  margin-bottom: 17px;
}

.banner.live-events-banner .banner-overlay .banner-overlay-description {
  background: #1F9BB4;
  padding-bottom: 13px;
}

.banner.live-events-banner .banner-overlay .banner-overlay-description p {
  margin-left: 2px;
}

.banner.live-events-banner .banner-image-container {
  background: url("/img/live-events-banner.png");
  background-size: 100% 100%;
}

.events-container {
  padding-top: 20px;
}

.events-container .section-heading {
  margin-top: 41px;
  margin-left: 5px;
  margin-bottom: 14px;
  width: 389px;
}

.events-container .event-img-container {
  display: inline-block;
  border-top-left-radius: 15%;
  overflow: hidden;
}

footer.live-events-footer {
  margin-top: 33px;
}

/*************** About page **********************/
.banner.about-banner .banner-image-container {
  background: url("/img/about-banner.png");
  background-size: 100% 100%;
}

.banner.about-banner .banner-overlay {
  margin-top: -86px;
}

.banner.about-banner .banner-overlay .banner-overlay-title,
.banner.about-banner .banner-overlay .banner-overlay-description {
  padding-left: 65px;
}

.banner.about-banner .banner-overlay .banner-overlay-title {
  padding-top: 16px;
}

.banner.about-banner .banner-overlay .banner-overlay-title h3 {
  margin-bottom: 18px;
}

.banner.about-banner .banner-overlay .banner-overlay-description {
  background: transparent;
  padding-bottom: 0;
  overflow: hidden;
  height: 233px;
}

.banner.about-banner .banner-overlay .banner-overlay-description .about-img {
  width: 407px;
  margin-top: 7px;
  margin-right: -7px;
}

.banner.about-banner .banner-overlay .banner-overlay-description .about-sub-header {
  margin-right: -49px;
  margin-top: 89px;
  width: 265px;
}

.banner.about-banner .banner-overlay .banner-overlay-description .about-description {
  display: inline-block;
  float: right;
}

.banner.about-banner .banner-overlay .banner-overlay-description .about-description h1 {
  font-family: 'Myriad Pro', Helvetica, Arial, Verdana;
  font-size: 71px;
  margin: 114px 7px 0 auto;
  font-weight: normal;
  line-height: 27px;
}

.banner.about-banner .banner-overlay .banner-overlay-description .about-description h1 span {
  color: #145c79;
}

.banner.about-banner .banner-overlay .banner-overlay-description .about-description .about-logo-img {
  margin-top: 63px;
  width: 112px;
  margin-right: 22px;
}

.features.about-feature .features-container .feature-box .feature-image-container,
.features.about-feature .features-container .feature-box .feature-caption {
  height: 543px;
}

.features.about-feature .features-container .feature-box .feature-image-container .feature-link {
  top: 52px;
}

.features.about-feature .features-container .feature-box .feature-caption {
  width: 67.66667%;
  padding-top: 50px;
}

.features.about-feature .features-container .feature-box .feature-caption p {
  padding-left: 8px;
  line-height: 20px;
  margin-bottom: 20px;
}

.partners-sponsors.about-partners .partner-with-us-panel-container {
  padding-left: 9px;
}

.partners-sponsors.about-partners .partner-with-us-panel-container .partner-with-us-panel .panel-body h4 {
  font-size: 20px;
  margin-top: 3px;
  margin-bottom: 2px;
}

.partners-sponsors.about-partners .partner-with-us-panel-container .partner-with-us-panel .panel-body a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
}

.footer.about-footer {
  margin-top: 14px;
}

/*************** Contact page **********************/
.banner.contact-banner .banner-overlay {
  margin-top: -86px;
}

.banner.contact-banner .banner-overlay .banner-overlay-title {
  padding-top: 16px;
}

.banner.contact-banner .banner-overlay .banner-overlay-title h3 {
  margin-bottom: 19px;
}

.banner.contact-banner .banner-image-container {
  background: url("/img/contact-banner.png");
  background-size: 100% 101%;
  background-position: 0 -4px;
}

.contact-info .section-container {
  width: 929px;
}

.contact-info .section-container .contact-info-container {
  margin: 66px 15px 0 2px;
  padding-top: 37px;
  border-top-left-radius: 30px;
  height: 520px;
  overflow: hidden;
}

.contact-info .section-container .contact-info-container .contact-column {
  padding-left: 7px;
}

.contact-info .section-container .contact-info-container .contact-column h4 {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
}

.contact-info .section-container .contact-info-container .contact-column h4.phone-title {
  margin-top: 22px;
}

.contact-info .section-container .contact-info-container .contact-column p {
  margin: 3px 0 0 0;
}

.contact-info .section-container .contact-info-container .contact-column p.contact-availability {
  font-size: 14px;
  margin: 5px 0 22px 0;
  color: #6C6C6C;
}

.contact-info .section-container .contact-info-container .contact-column a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
}

.contact-info .section-container .contact-info-container .contact-column a:hover, .contact-info .section-container .contact-info-container .contact-column a:active, .contact-info .section-container .contact-info-container .contact-column a:focus {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.contact-info .section-container .contact-info-container .contact-column address {
  margin: 2px 0 20px 0;
  line-height: 22px;
  font-style: normal;
}

.contact-info .section-container .contact-info-container .envelope-img {
  width: 433px;
  margin-left: 8px;
  margin-top: 118px;
}

.footer.contact-info-footer {
  margin-top: 64px;
}

/*************** MedSpotlight Page **********************/
.banner.medspotlight-banner .banner-overlay {
  margin-top: -86px;
}

.banner.medspotlight-banner .banner-overlay .banner-overlay-title {
  background-color: transparent;
  padding-top: 16px;
}

.banner.medspotlight-banner .banner-overlay .banner-overlay-title h3 {
  margin-bottom: 17px;
}

.banner.medspotlight-banner .banner-image-container {
  background: url("/img/medenergy-banner.jpg");
}

.banner.medspotlight-banner .banner-overlay-form {
  background-color: #1F9BB4;
  margin-left: 0px;
  padding-top: 21px;
  padding-bottom: 29px;
  background: url("/img/medenergy-content-bg.jpg") no-repeat top left;
  min-height: 340px;
}

.banner.medspotlight-banner .banner-overlay-form .btn-lg {
  margin-top: 7px;
  width: auto;
  padding: 5px 20px;
  float: left;
}

.banner.medspotlight-banner .banner-overlay-form .btn-lg.share-btn {
  margin-left: 15px;
  padding: 5px 18px;
}

.banner.medspotlight-banner .banner-overlay-form .col-12 {
  padding-left: 0px;
}

.banner.medspotlight-banner .banner-overlay-form .social-media {
  display: inline-block;
  margin-top: 8px;
  float: left;
  margin-left: 14px;
}

.banner.medspotlight-banner .banner-overlay-form .social-media a {
  width: 33px;
  height: 33px;
  font-size: 32px;
  font-family: 'Folio', Helvetica, Arial, Verdana;
}

.banner.medspotlight-banner .banner-overlay-form .social-media a.twitter-btn {
  margin-left: 11px;
  line-height: 24px;
  font-size: 30px;
}

.banner.medspotlight-banner .medspotlight-banner-container {
  line-height: 0;
}

.banner.medspotlight-banner .banner-overlay-description {
  padding-bottom: 55px;
}

.banner.medspotlight-banner .banner-overlay-description .medspotlight-description h3 {
  margin-top: 27px;
  margin-bottom: 0px;
  letter-spacing: -0.1px;
}

.banner.medspotlight-banner .banner-overlay-description .medspotlight-description p {
  line-height: 20px;
}

.banner.medspotlight-banner .banner-overlay-description .medspotlight-description p.upload-date {
  margin-top: 0;
  opacity: 0.7;
  margin-bottom: 20px;
}

.medspotlight-img-container .img-content {
  margin-right: 20px;
}

.medspotlight-img-container .img-content .medspotlight-sub-header {
  margin: 88px 0px 0 auto;
  width: 360px;
}

.medspotlight-img-container .img-content p {
  font-size: 14px;
  margin-top: 2px;
  line-height: 20px;
  margin-right: 3px;
}

.medspotlight-img-container .briefcase {
  width: 302px;
  margin-top: -28px;
  margin-right: 5px;
}

.medspotlight-img-container .spotlight {
  margin-top: 89px;
  margin-right: 23px;
  width: 117px;
}

.features.medspotlight-features {
  margin-top: 20px;
  margin-bottom: 42px;
}

.features.medspotlight-features .related-channel-title {
  font-weight: normal;
  font-size: 22px;
  margin-left: 1px;
  margin-bottom: 14px;
}

.features.medspotlight-features .features-container .feature-box {
  margin-bottom: 13px;
  height: 180px;
  overflow: hidden;
}

.features.medspotlight-features .features-container .feature-box .feature-image-container,
.features.medspotlight-features .features-container .feature-box .feature-caption {
  height: 100%;
}

.features.medspotlight-features .features-container .feature-box .feature-caption {
  padding-left: 13px;
  padding-right: 50px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption .title {
  margin-top: 23px;
  padding-left: 9px;
  color: rgba(20, 92, 121, 0.75);
  font-weight: normal;
  font-family: Arial;
  font-size: 18px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption .title sup {
  font-size: 10px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption .course-info {
  margin-bottom: 20px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption .course-info .course-category {
  margin-right: 4px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption .course-info .course-length {
  padding-left: 7px;
  padding-right: 7px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption .course-info .course-length .time {
  padding-left: 4px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption .course-info .course-expire-date {
  padding-left: 8px;
}

.features.medspotlight-features .features-container .feature-box .feature-caption p {
  margin-top: 0;
  line-height: 20px;
}

.footer.medspotlight-footer {
  margin-top: 0;
}

/*************** Login Page **********************/
.login-page .section-container {
  padding-left: 0;
}

.login-page .section-container .contact-info-container {
  margin-top: 0;
  border-top-left-radius: 0;
}

.login-page .footer {
  margin-top: 0;
}

/*************** Slide Player **********************/
.reveal.slide {
  height: 600px;
}

.reveal.slide section {
  height: 600px;
}

.reveal.slide .audiojs {
  display: none;
}

.vjs-control-bar {
  font-size: 13px !important;
  line-height: 3em !important;
}

.vjs-play-progress,
.vjs-volume-level {
  background-color: #9FD443 !important;
}

.vjs-big-play-centered .vjs-big-play-button {
  margin-left: -1.3em !important;
  width: 2.6em !important;
  height: 2.6em !important;
  border-radius: 50% !important;
}

/*************** Course Player **************/
.course-player {
  font-family: Arial, Helvetica, Arial, Verdana;
}

.course-player .course-title {
  font-family: Arial, Helvetica, Arial, Verdana;
  background: #0C3E4F;
  min-height: 79px;
  color: white;
  padding-left: 23px;
}

.course-player .course-navigation {
  margin-top: 45px;
  height: 52px;
  background: #0C3E4F;
  color: white;
  font-family: Arial, Helvetica, Arial, Verdana;
}

.course-player .course-navigation a {
  text-decoration: none;
  color: white;
}

.course-player .course-navigation .course-links {
  padding-top: 2px;
}

.course-player .slides {
  width: 100% !important;
  height: 100% !important;
  zoom: 1 !important;
}

/**************** 404 Page ***************/
.page-404 .message-container {
  min-height: 500px;
  padding-top: 200px;
}

/*# Redesign July 2019 */
.main-header {
  background: url(../img/main-header.png) no-repeat;
}

.header .header-navbar-container {
  margin-top: 5px;
}

.misc-nav {
  width: 460px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.misc-nav a {
  text-decoration: none;
  font-size: 15px;
}

.nav-social {
  width: 100px;
  margin-top: 3px;
  margin-left: 20px;
}

.nav-social a {
  margin-right: 2px;
}

.nav-social a .fa-2x {
  font-size: 1.4em !important;
}

.misc-nav-container {
  width: 100%;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar-container {
  clear: both;
}

.top-footer {
  background: #A0D544;
  padding: 10px 60px;
}

.top-footer p {
  margin: 0;
  text-align: right;
  color: #fff;
  font-size: 14px;
  margin-top: 2px;
}

.top-footer a {
  color: #fff;
  text-decoration: none;
}

.footer-navbar li {
  font-size: 14px;
}

.banner-video {
  overflow: hidden;
  height: 390px;
  border-bottom: 22px solid #94C947;
}

.content-title {
  background: #1F9CB4;
}

.content-title h3 {
  font-family: 'Folio';
  font-size: 31px;
  color: #fff;
  font-weight: normal;
  padding: 0px 66px;
  margin: 8px 0;
}

.content-title p {
  font-size: 17px;
  line-height: 20px;
  color: #fff;
  font-weight: normal;
  padding: 0px 66px;
  margin: 20px 0;
}

.title-shadow {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 9;
}

.gradient-bg,
.recommended-reading {
  background: url(../img/gradient1.png) repeat-x bottom;
}

.recommended-reading .inner-container {
  background: #fff;
  margin: 0 40px;
  min-height: 350px;
  overflow: hidden;
}

.recommended-reading .cycle-slideshow img {
  display: block;
}

.rr-text {
  width: 610px;
  float: left;
  text-align: right;
  padding-top: 60px;
}

.rr-text p {
  margin: 0 0 13px;
}

.rr-text p:nth-child(1) {
  color: #878787;
  font-size: 20px;
}

.rr-text p:nth-child(2) {
  color: #3A65C7;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
}

.rr-text p:nth-child(3) {
  color: #EA3ECA;
  font-size: 22px;
}

.rr-text a {
  color: #3A65C7;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
  background: url(../img/link-icon1.png) no-repeat right;
  padding: 10px 45px 10px 0;
  margin-top: 0px;
  display: block;
}

.rr-book {
  width: 288px;
  float: left;
  padding: 20px 40px 0 0;
  margin-left: -5px;
}

.carousel {
  margin: 0 0 0 2px;
  padding: 0 25px;
}

.carousel .item {
  color: #747d89;
  min-height: 334px;
  text-align: center;
  overflow: hidden;
}

.carousel .thumb-wrapper {
  padding: 0px;
  background: #fff;
  height: 344px;
  text-align: left;
  position: relative;
  /*box-shadow: 0 2px 3px rgba(0,0,0,0.2);*/
}

.carousel .item .img-box {
  height: 196px;
  width: 100%;
  position: relative;
}

.carousel .item img {
  width: 210px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
  object-fit: fill;
}

.carousel .item h4 {
  font-size: 14px;
  color: #005061;
  line-height: 1.2;
  font-weight: normal;
}

.carousel .item p {
  font-size: 12px;
  color: #BABABA;
}

.carousel .item h4,
.carousel .item p,
.carousel .item ul {
  margin-bottom: 5px;
}

.carousel .thumb-content {
  padding: 0px 15px 15px;
}

.carousel .thumb-content .btn {
  color: #7ac400;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: 1px solid transparent;
  padding: 2px 14px;
  margin-top: 0;
  line-height: 16px;
  border-radius: 20px;
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.carousel .text-right {
  text-align: right;
}

.carousel .thumb-content .btn:hover,
.carousel .thumb-content .btn:focus {
  color: #fff;
  background: #7ac400;
  box-shadow: none;
}

.carousel .thumb-content .btn i {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}

.carousel .carousel-control {
  height: 44px;
  width: 15px;
  background: transparent;
  background-image: none !important;
  margin: auto 0;
  border-radius: 4px;
  opacity: 1;
}

.carousel .carousel-control:hover {
  background: transparent;
  opacity: 0.8;
}

.carousel .carousel-control i {
  font-size: 48px;
  position: absolute;
  top: 50%;
  display: inline-block;
  margin: -19px 0 0 0;
  z-index: 5;
  left: 0;
  right: 0;
  color: #A0D544;
  text-shadow: none;
  font-weight: bold;
}

.carousel .item-price strike {
  opacity: 0.7;
  margin-right: 5px;
}

.carousel .carousel-control.left i {
  margin-left: -2px;
}

.carousel .carousel-control.right i {
  margin-right: -4px;
}

.carousel .carousel-indicators {
  bottom: -50px;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 50%;
  border-color: transparent;
}

.carousel-indicators li {
  background: rgba(0, 0, 0, 0.2);
}

.carousel-indicators li.active {
  background: rgba(0, 0, 0, 0.6);
}

.banner-container {
  min-height: 393px;
  position: relative;
}

.banner-1 {
  background: url(../img/banner1.jpg) no-repeat;
  background-size: contain;
}

.banner-2 {
  background: url(../img/banner2.jpg) no-repeat;
  background-size: contain;
}

.banner-3 {
  background: url(../img/banner3.jpg) no-repeat;
  background-size: contain;
}

.banner-4 {
  background: url(../img/banner4.jpg) no-repeat;
  background-size: contain;
}

.banner-more-to-explore {
  background: url(../img/banner-xinstitute.jpg) no-repeat;
  background-size: contain;
}

.banner-text1 {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 300px;
  height: 65px;
}

.banner-text2 {
  position: absolute;
  bottom: 20px;
  left: 370px;
  width: 550px;
  height: 65px;
}

.banner-container h1 {
  font-size: 30px;
  line-height: 1;
  margin-top: 15px;
  padding-left: 66px;
  color: #fff;
  font-weight: normal;
}

.banner-container p {
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  margin-top: 15px;
}

.cme-iframe {
  width: 975px;
  height: 100vh;
  border: none;
  margin-left: -15px;
  margin-right: -15px;
}

.course-search {
  background: #1E9BB4;
  padding: 32px 66px;
}

.course-search select,
.course-search p,
.course-search a {
  float: left;
  display: inline-block;
  margin-right: 20px;
}

.course-search select {
  font-size: 13px;
  height: 34px;
  width: 214px;
}

.course-search p {
  color: #fff;
  font-size: 13px;
  margin-top: 7px;
}

.course-search a {
  background: #94C947;
  color: #fff;
  font-size: 18px;
  border-radius: 60px;
  padding: 7px 30px;
  text-decoration: none;
}

.course-search a:hover {
  background: #146079;
  color: #fff;
  text-decoration: none;
}

.more-to-explore .features {
  padding-top: 40px;
  padding-bottom: 0px;
}

/* carousel */
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -moz-transition: -moz-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
  }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    left: 0;
  }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    left: 0;
  }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}

.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}

.carousel-control:hover,
.carousel-control:focus {
  outline: 0;
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  z-index: 5;
  display: inline-block;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}

.carousel-control .icon-prev:before {
  content: '\2039';
}

.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
}

.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

.fa-angle-right:before {
  content: url(../img/carousel-next.png) !important;
}

.fa-angle-left:before {
  content: url(../img/carousel-previous.png) !important;
}

.carousel .carousel-control.left i {
  margin-left: -8px !important;
}

.carousel .carousel-control.right i {
  margin-right: 2px !important;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }
  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}

.col-md-12 {
  width: 100%;
  float: left;
}

.col-sm-3 {
  width: 230px;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.media-container {
  padding-top: 60px;
  padding-bottom: 40px;
}

.media-container article {
  background: #F0F0F0;
  border-top-left-radius: 30px;
  margin: 0 15px 15px;
  display: flex;
  position: relative;
}

.media-container .date-vertical {
  border-bottom-right-radius: 30px;
  font-weight: bold;
  font-size: 24px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  color: #fff;
  background: #1E9BB4;
  padding: 25px 17px;
  float: left;
}

.media-container h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  color: #15617A;
  margin: 0 0 20px;
}

.media-container .caption {
  padding: 20px;
  float: left;
}

.media-container p {
  font-size: 14px;
  position: absolute;
  bottom: 13px;
}

.media-container .thumbnail-link {
  position: absolute;
  right: -10px;
  bottom: 20px;
}

.media-container .thumbnail-link span {
  font-size: 14px;
  margin: 5px 10px;
}

.more-to-explore-p {
  height: 40px;
  overflow: hidden;
}

/* IE hack */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .live-events .events-container .row.events-row .event-box .thumbnail .caption {
    overflow: hidden;
  }
  .media-container article {
    background: #F0F0F0;
    border-top-left-radius: 30px;
    margin: 0 15px 15px;
    position: relative;
  }
  .media-container .caption {
    display: block;
    width: 750px;
    height: 145px;
    margin-left: -40px;
  }
  .media-container .date-vertical {
    border-top-left-radius: 0px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-weight: bold;
    font-size: 24px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(270deg);
    color: #fff;
    background: #1E9BB4;
    padding: 25px 17px;
    display: block;
    float: left;
    width: 145px;
    height: 60px;
    margin-top: 43px;
    margin-left: -40px;
  }
  .live-events-p {
    height: 80px;
    overflow: hidden;
  }
}

/*************** Cutera **************/
.cutera .banner.umbrella-banner .banner-image-container {
  background: url("/img/cutera-banner.png");
  background-size: cover;
}

.cutera .banner.umbrella-banner .banner-overlay-solid {
  margin-top: -54px;
}

.cutera .banner.umbrella-banner .course-content .course-container .course-title-container {
  margin-top: 15px;
}

.cutera .banner.umbrella-banner .course-content .course-container .course-detail-container {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 11px;
}

.cutera-video .banner.medchannel-program-banner .banner-overlay {
  margin-top: 0;
  border-top-right-radius: 0;
  width: auto;
}

.cutera-video .banner.medchannel-program-banner .banner-image-container {
  background: url("/img/cutera-banner.png");
  background-size: 100% 100%;
}

/*************** Cytrellis **************/
.cytrellis .banner.umbrella-banner .banner-image-container {
  background: url("/img/cytrellis-banner.jpeg");
  background-size: cover;
}

.cytrellis .banner.umbrella-banner .banner-overlay-solid {
  margin-top: -20px;
}

.cytrellis .banner.umbrella-banner .course-content .course-container .course-title-container {
  margin-top: 15px;
}

.cytrellis .banner.umbrella-banner .course-content .course-container .course-detail-container {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 11px;
}

.cytrellis-video .banner.medchannel-program-banner .banner-overlay {
  margin-top: 0;
  border-top-right-radius: 0;
  width: auto;
}

.cytrellis-video .banner.medchannel-program-banner .banner-image-container {
  background: url("/img/cytrellis-banner.jpeg");
  background-size: 100% 100%;
}

/*************** Resources **************/
.banner-5 {
  background: url(../img/banner5.jpg) no-repeat;
  background-size: contain;
}

.resources-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.accordion-container {
  margin: 0 0 30px;
  position: relative;
}

.accordion-container:last-child {
  margin-bottom: 0px;
}

.accordion-toggle {
  position: relative;
  display: block;
  padding: 20px 10px 20px 60px;
  font-size: 1.5em;
  font-weight: 300;
  background: #1E9BB4;
  color: #fff;
  text-decoration: none;
}

.accordion-toggle.open,
.accordion-toggle:hover {
  background: #1E9BB4;
}

.accordion-toggle span.toggle-icon {
  position: absolute;
  top: 21px;
  left: 25px;
  font-size: 20px;
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
}

.accordion-toggle.open span.toggle-icon {
  transform: rotate(180deg);
}

.accordion-content {
  display: none;
  padding: 0px;
  overflow: auto;
  border: 1px solid #c2c2c2;
}

.search-resources {
  border: 1px solid #00667b;
  background: #fff;
  width: 285px;
  position: absolute;
  z-index: 99;
  right: 20px;
  top: 17px;
}

.search-resources input[type=text] {
  border: none;
  width: 85%;
  float: left;
  padding: 8px 20px;
  font-size: 14px;
}

.search-resources input[type=text]::placeholder {
  color: #bdbdbd;
}

.search-resources input[type=submit] {
  border: none;
  width: 15%;
  float: left;
  background: url(/img/search-icon.png) center no-repeat;
  background-size: 26px;
  height: 32px;
}

.asset-container {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.asset-container li {
  display: flex;
  border-bottom: 1px solid #c2c2c2;
  overflow: hidden;
  align-items: center;
}

.asset-container li:last-child {
  border-bottom: none;
}

.asset-description {
  background: #c2c2c2;
  color: #fff;
  padding: 10px 20px;
}

.asset-thumbnail {
  width: 85px;
}

.asset-thumbnail img {
  max-width: 85px;
  margin-bottom: -4px;
}

.asset-title {
  flex: 1;
  padding: 20px 30px;
  line-height: 1.3;
  font-size: 14px;
}

.asset-link {
  width: 120px;
}

.asset-link a {
  text-decoration: none;
  color: #71b300;
  padding: 20px 20px 20px 0;
  line-height: 2.3;
  display: block;
  font-size: 14px;
  text-align: right;
}

.asset-link a:hover {
  color: #3BA0BA;
}

/* Responsive Page */
.r-page-wrapper {
  max-width: 980px !important;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 10px 0 25px -8px rgba(0, 0, 0, 0.3), -10px 0 25px -8px rgba(0, 0, 0, 0.3);
  padding: 0 15px;
}

.r-header {
  background: #3aa0b9;
  color: #fff;
  border-bottom: 3px #9fd443 solid;
  position: relative;
  z-index: 10;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.r-header .r-header-navbar-container ul.nav-bar .dropmenu {
  position: relative;
}

.r-header .r-header-navbar-container ul.nav-bar .dropmenu ul {
  width: 200px;
  position: absolute;
  background-color: transparent;
  display: none;
  left: -5px;
  z-index: 1000;
  top: 12px;
}

.r-header .r-header-navbar-container ul.nav-bar .dropmenu ul li.first {
  margin-top: 17px;
}

.r-header .r-header-navbar-container ul.nav-bar .dropmenu ul li {
  background-color: rgba(0, 40, 60, 0.75);
  padding: 4px 0px 6px 10px;
}

.r-header .r-header-navbar-container ul.nav-bar .dropmenu ul a {
  display: block;
  color: white;
  font-size: 14px;
}

.r-header .r-header-navbar-container ul.nav-bar .dropmenu:hover ul {
  display: inline-block;
}

.r-header .r-header-navbar-container .nav-bar li a span {
  position: absolute;
  width: 15px;
  height: 13px;
  top: -18px;
  left: 50%;
  margin-left: -9.5px;
  background: url("/img/arrow.png") no-repeat center top;
  visibility: hidden;
}

.r-header .r-header-navbar-container .nav-bar li a:hover span, .r-header .r-header-navbar-container .nav-bar li a:active span, .r-header .r-header-navbar-container .nav-bar li a:focus span {
  visibility: visible;
}

.r-header .r-header-navbar-container .nav-bar li.active a span {
  visibility: visible;
}

.r-header .r-header-navbar-container .nav-bar li.last {
  padding-right: 0;
}

.r-header a {
  color: #fff;
}

.r-header-navbar-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.r-misc-nav-container {
  background: #2c728c;
  clear: both;
}

.r-misc-nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 100%;
}

.r-misc-nav a {
  text-decoration: none;
}

.r-nav-social {
  width: 100px;
  margin: 0px 18px;
}

.r-nav-social a .fa-2x {
  font-size: 1.5em !important;
  margin: 2px;
}

.r-nav-bar-container {
  background: #22607a;
  display: flex;
  margin: 0;
  padding: 10px;
  list-style: none;
  width: 100%;
  justify-content: center;
}

.r-nav-bar-container li {
  padding: 0 9px 0 8px;
}

.r-nav-bar-container li a {
  position: relative;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

.r-header .header-title-container .site-title {
  font-family: 'Folio Bold', Helvetica, Arial, Verdana;
  margin: 0;
  padding: 0;
  line-height: 39px;
  font-size: 39px;
  font-weight: normal;
  margin-top: 16px;
  margin-left: 8px;
}

.r-header .header-title-container .site-title sup {
  display: inline-block;
  font-size: 8px;
  vertical-align: top;
  margin-top: 12px;
  margin-left: -2px;
  font-family: Arial, Helvetica, Arial, Verdana;
}

.r-header .header-title-container {
  width: 240px;
  display: flex;
  margin: 20px auto;
}

@media (max-width: 677px) {
  .reveal.slide {
    height: 63vw;
  }
  .reveal.slide section {
    padding-top: 0 !important;
    height: 63vw;
  }
  .r-misc-nav > li {
    padding: 0;
  }
  .r-misc-nav a {
    font-size: 13.5px;
  }
  .responsive .top-footer p {
    text-align: center;
  }
  .responsive footer .col-10 {
    float: auto;
    width: 100%;
    clear: both;
    text-align: center;
  }
  .responsive footer .col-2 {
    float: auto;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .responsive .r-nav-social {
    width: 83px;
    margin: 0px 0 0 3px;
  }
  .r-nav-bar-container li a {
    font-size: 16px;
  }
  .r-course-title {
    align-content: flex-end;
    order: 8;
    width: 100%;
    flex: 1 0 100%;
  }
  .r-course-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .r-course-logo-menu {
    width: 52px;
    flex: 1;
    background: #94c947;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .r-course-logo {
    display: none;
  }
  .r-course-next-button {
    background-size: contain !important;
    background-color: #22607a !important;
  }
  .r-course-close-button {
    margin-left: auto;
  }
  .r-course-title {
    border-top: 2px solid #98ca46;
  }
  .sidebar {
    height: 63vw !important;
    width: 315px;
    top: 93px !important;
  }
}

@media (min-width: 678px) {
  .r-header .header-title-container {
    margin-top: 20px;
  }
  .r-header {
    background: #3aa0b9;
    background: linear-gradient(90deg, #3aa0b9 0%, #266777 7%, #3aa0b9 7%, #3aa0b9 100%);
    position: relative;
  }
  .r-nav-bar-container {
    padding: 11px 32px;
  }
  .r-nav-bar-container li a {
    position: relative;
    font-size: 20px;
    line-height: 20px;
  }
  .r-course-header {
    background: #266777;
  }
  .r-course-logo-menu {
    width: 174px;
  }
  .r-course-content {
    border-top: 4px solid #98ca46;
    min-height: 600px;
  }
}

@media (min-width: 678px) and (max-width: 899px) {
  .reveal.slide {
    height: 60vw;
  }
  .reveal.slide section {
    padding-top: 0 !important;
    height: 60vw;
  }
  .sidebar {
    height: 60vw !important;
  }
}

@media (min-width: 900px) {
  .r-header {
    height: 112px;
  }
  .r-header-navbar-container {
    width: 647px;
  }
  .r-header-navbar-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .r-misc-nav-container {
    border-top-left-radius: 25px;
  }
  .r-nav-bar-container {
    justify-content: flex-end;
  }
  .r-misc-nav-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
  }
  .r-header .header-title-container {
    width: 280px;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
  }
  .responsive .r-nav-social {
    width: 100px;
    margin: 0px 14px;
  }
  .r-course-header {
    background: #266777;
    height: 46px;
  }
  .r-course-next-button {
    width: 68px !important;
    background-color: #22607a !important;
  }
  .sidebar {
    width: 334px !important;
  }
  .hamburger:hover .hamburger__icon,
  .hamburger.active .hamburger__icon {
    background: #22607a;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .responsive .top-footer p {
    text-align: center;
  }
  .responsive footer .col-10 {
    float: auto;
    width: 510px;
    clear: both;
    text-align: center;
  }
  .responsive footer .col-2 {
    float: auto;
    margin: 0;
    width: 170px;
  }
  .responsive footer {
    display: flex;
    justify-content: center;
  }
  .r-course-title {
    align-content: flex-end;
    order: 8;
    width: 100%;
    flex: 1 0 100%;
  }
  .r-course-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .r-course-logo-menu {
    width: 52px;
    flex: 1;
    align-content: flex-start;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .r-course-player button {
    background-size: contain;
  }
  .r-course-next-button {
    flex: 1;
    background-size: contain !important;
    background-color: #16617A;
  }
  .r-course-title {
    border-top: 3px solid #98ca46;
  }
  .sidebar {
    top: 93px !important;
  }
}

.r-course-player {
  width: 100%;
}

.r-course-header {
  background: #16617A;
  border-bottom: 2px solid #98ca46;
  display: flex;
}

.r-course-player button {
  height: 45px;
  border: none;
  margin: 0;
  padding: 0;
  background-color: #16617A;
}

.r-course-player button:hover {
  filter: brightness(0.7);
}

.reference-container p {
  color: #8d8d8d;
}

.reference-container a, .reference-container a:hover, .reference-container a:active, .reference-container a:visited {
  color: #4b6c80;
}

@media (hover: none) and (pointer: coarse) {
  .r-course-player button:hover {
    filter: none;
  }
}

.r-course-logo {
  width: 125px;
  background: url(../img/course-logo.svg) no-repeat;
  background-size: cover;
  height: 45px;
}

.r-course-logo-menu {
  background: #94c947;
  width: 50px;
}

.r-course-slider {
  position: relative;
  display: flex;
}

.r-course-back-button {
  width: 60px;
  background: url(../img/02_BackButton.svg) no-repeat;
}

.r-course-pause-button {
  width: 40px;
  background: url(../img/03_PauseButton.svg) no-repeat;
}

.r-course-play-button {
  width: 60px;
  background: url(../img/03_PlayButton.svg) no-repeat;
}

.r-course-next-button {
  width: 60px;
  background: url(../img/04_NextButton.svg) no-repeat;
}

.r-course-bookmark-button {
  width: 60px;
  background: url(../img/06_Bookmark.svg) no-repeat;
}

.r-course-download-button {
  width: 64px;
  background: url(../img/07_Download-01.svg) no-repeat;
}

.r-course-close-button {
  width: 52px;
  height: 45px;
  background: url(../img/08_CloseButton.svg) no-repeat;
}

.r-course-title {
  width: auto;
  flex: 1;
  height: 45px;
  background: #1b4d61;
  background: linear-gradient(90deg, #1b4d61 0%, #22607a 10%, #22607a 100%);
  align-items: center;
  color: #fff;
  display: flex;
  padding: 0 30px;
}

.r-course-content {
  background: white;
  border-top: 2px solid #98ca46;
}

.hamburger {
  position: absolute;
  right: 9px;
  top: 13px;
  display: flex;
  height: 18px;
  width: 24px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}

.hamburger__icon {
  display: inline-block;
  height: 4px;
  width: 18px;
  background: #fff;
}

.sidebar {
  height: 598px;
  width: 315px;
  background: url(../img/10_DropDownPanel.svg) no-repeat #47494c;
  position: absolute;
  top: 48px;
  left: 0;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  transform: translatex(-100%);
  transition: transform 0.4s ease-in-out;
  z-index: 100;
  overflow-y: auto;
}

.sidebar__menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.sidebar__menu h3:first-child {
  margin-top: 0px;
}

.sidebar__menu h3 {
  color: #98ca46;
  margin-top: 10px;
  margin-bottom: 5px;
}

.sidebar__menu a {
  color: #fff;
  margin-bottom: 5px;
  text-decoration: none;
  transform: translatex(-80%);
  transition: transform 0.4s ease-in-out;
}

.sidebar__menu a::before {
  content: " ";
  background: url(../img/11_DropDownCheck.svg);
  background-size: contain;
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 999;
  left: -20px;
  transform: translatex(-50%);
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s linear;
}

.sidebar__menu a.active::before {
  transform: translatex(0);
  opacity: 1;
}

.menu-control {
  display: none;
}

.menu-control:checked + .sidebar {
  transform: translatex(0);
}

.menu-control:checked + .sidebar .sidebar__close {
  transform: translatex(0) rotate(45deg);
}

.menu-control:checked + .sidebar .sidebar__menu a {
  transform: translatex(0);
}

/* Landing Decision Page */
body.landing-decision {
  background-color: #003c57;
}

.landing-decision .container {
  width: 100%;
  margin: 0 auto;
  background: #003c57;
  min-height: 500px;
  padding: 0;
  font-family: Arial, sans-serif;
}

.landing-decision .header {
  padding: 15px;
  font-size: 19px;
  color: #fff;
  text-align: center;
  border: 0;
  height: 80px;
  box-shadow: none;
  background: none;
}

.landing-decision span {
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  color: #a0d544;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
}

.landing-decision .selections {
  background: url(../img/landingdecision-bg.jpg) no-repeat top center;
  background-size: cover;
  padding: 20px;
}

.landing-decision .selections a {
  color: inherit;
  text-decoration: inherit;
}

.landing-decision .boxes {
  width: 256px;
  height: 387px;
  background: #fff;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
}

.landing-decision .boxes img {
  width: 218px;
  height: 218px;
}

.landing-decision .boxes h3 {
  color: #94c947;
  font-size: 22px;
  margin: 15px 0 5px;
}

.landing-decision .boxes p {
  font-size: 12px;
  margin-top: 0;
}

.landing-decision .content {
  color: #fff;
  padding: 20px 30px;
  text-align: center;
}

.landing-decision .content p {
  font-size: 18px;
  line-height: 24px;
}

.landing-decision .content p:last-child {
  margin-top: 40px;
}

.landing-decision .content img {
  width: 206px;
  height: 56px;
}

@media (min-width: 768px) {
  .landing-decision .header {
    height: 60px;
  }
  .landing-decision .selections-container {
    margin: auto;
    display: flex;
  }
  .landing-decision .boxes {
    margin-top: 14px;
  }
}

@media (min-width: 900px) {
  .landing-decision .container {
    width: 980px;
    margin: 0 auto;
    background: #003c57;
    min-height: 500px;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  .landing-decision .selections {
    padding: 35px;
  }
  .landing-decision .selections-container {
    width: 740px;
    margin: auto;
    display: flex;
  }
  .landing-decision .boxes {
    width: 256px;
    height: 387px;
    background: #fff;
    margin: 0 55px;
    padding: 20px;
    text-align: center;
  }
  .landing-decision .content img {
    margin-top: -25px;
  }
  .landing-decision .content {
    padding: 0 170px 40px;
  }
}

/*============= Aesthetics Markup - March 2024 ============== */
.aesthetics-central,
.aesthetics-central h3,
.aesthetics-central p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.aesthetics-central header {
  background: url(../img/AC-header-bg.png) top repeat-x;
  height: 220px;
  text-align: center;
  border-bottom: none;
  position: relative;
  box-shadow: none;
}

.aesthetics-central .header-logo {
  background: url(../img/AC-logo.png) top repeat-x;
  position: relative;
  z-index: 2;
  height: 148px;
  width: 457px;
  margin: auto;
}

.aesthetics-central .nav-bar {
  text-align: center !important;
  padding-top: 0px !important;
}

.aesthetics-central .nav-bar a {
  height: 44px;
  padding: 12px;
  font-size: 22px;
  display: block;
}

.aesthetics-central .nav-bar a:hover, .aesthetics-central .nav-bar a:active, .aesthetics-central .nav-bar a:focus {
  color: #f2f2f2 !important;
  background: url(../img/AC-active-arrow.png) bottom no-repeat;
}

.aesthetics-central .nav-bar .active {
  background: url(../img/AC-active-arrow.png) bottom no-repeat;
}

.aesthetics-central .header-navbar-container {
  width: 100%;
  background: #ff6666;
  height: 44px;
  float: none;
  position: absolute;
  bottom: 0;
  left: 0;
  justify-content: center;
}

.aesthetics-central .intro-text {
  background: url(../img/AC-intro-text-bg.png) top repeat-x #ff6666;
  color: #fff;
  padding: 20px 30px 30px;
  letter-spacing: 1px;
  text-align: center;
}

.aesthetics-central h3 {
  margin-bottom: 0px;
  font-size: 30px;
}

.aesthetics-central .intro-text h3,
.aesthetics-central .intro-text p {
  margin: 0;
}

.aesthetics-central .banner-container {
  background: url(../img/AC-header.jpg) no-repeat bottom;
  background-size: cover;
  min-height: 393px;
}

.aesthetics-central .features {
  padding-top: 60px;
}

.aesthetics-central .feature-link.arrow {
  display: block;
  background: url(../img/AC-arrow-right.png) no-repeat bottom;
  height: 28px !important;
  width: 26px;
}

.aesthetics-central .xmedica-paginator .pagination span, .aesthetics-central .xmedica-paginator .pagination a {
  color: #ff6666;
  font-weight: 400;
}

.aesthetics-central .feature-caption .title {
  color: #666 !important;
}

.aesthetics-central footer {
  background-color: #666666;
  margin-top: 0px;
  border-top: none;
  font-weight: 400;
}

.aesthetics-central footer li span {
  color: #ff6666 !important;
}

.aesthetics-central .popover-content {
  color: rgba(0, 0, 0, 0.75);
}

.aesthetics-central .btn-primary,
.aesthetics-central .banner .banner-overlay .btn-lg {
  background-color: #acacac;
}

.aesthetics-central .banner.contact-banner .banner-image-container,
.aesthetics-central .banner.registration-banner .banner-image-container {
  background: url(../img/AC-header.jpg) no-repeat bottom;
  background-size: cover;
  min-height: 393px;
}

.aesthetics-central .banner.registration-banner .banner-overlay-form-container {
  background: none;
  padding-top: 50px;
}

.aesthetics-central .banner.registration-banner .banner-overlay-form-container .form-panel .panel-heading,
.aesthetics-central .banner.registration-banner .banner-overlay-form-container .form-footer,
.aesthetics-central .banner.registration-banner .banner-overlay-form-container .npi-number {
  background-color: #ff6666;
}

.aesthetics-central .banner .banner-overlay {
  width: 100%;
  margin-top: 0;
  border-top-right-radius: 0;
  background: url(../img/AC-intro-text-bg.png) top repeat-x;
  color: #fff;
  padding: 20px 0 30px;
  letter-spacing: 1px;
}

.aesthetics-central .banner .banner-overlay .banner-overlay-title h3 {
  text-align: center;
  margin: 0;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.aesthetics-central .banner .banner-overlay .banner-overlay-title h3 strong {
  font-family: "Roboto", sans-serif;
}

.aesthetics-central .banner .banner-overlay .banner-overlay-title {
  background: none;
  padding: 0;
}

.aesthetics-central .text-highlight {
  color: unset !important;
  font-weight: 300;
  font-size: 30px;
}

.aesthetics-central .section-container:not(.features-container) {
  width: 100%;
  padding: 0;
}

.aesthetics-central .section-container .contact-info-container {
  margin: 0;
  border-radius: 0;
}
