@font-face {
  font-family: 'Folio';
  src: url('../fonts/FolioLig.otf'); /* IE9 Compat Modes */
  src: url('../fonts/FolioLig.otf?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/FolioLig.otf') format('woff'), /* Modern Browsers */
       url('../fonts/FolioLig.otf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Folio Medium';
  src: url('../fonts/FolioMed.otf'); /* IE9 Compat Modes */
  src: url('../fonts/FolioMed.otf?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/FolioMed.otf') format('woff'), /* Modern Browsers */
       url('../fonts/FolioMed.otf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Folio Bold';
  src: url('../fonts/FolioBol.otf'); /* IE9 Compat Modes */
  src: url('../fonts/FolioBol.otf?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/FolioBol.otf') format('woff'), /* Modern Browsers */
       url('../fonts/FolioBol.otf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro'), url('../fonts/MYRIADPRO-REGULAR.woff') format('woff');
}
